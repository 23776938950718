<template>
  <div class="card-body">
    <h5>
      <span>{{ index + 1 }}</span>{{ roomClassName || ''}}
    </h5>

    <div class="d-flex justify-content-between">
      <div class="p-2" :style="lang === 'he'? 'direction:rtl;':'direction:ltr;'">
        <span v-if="Number(room.adult) === 1">{{ room.adult }} {{ $t("product-page.adult") }}</span>
        <span v-else>{{ room.adult }} {{ $t("product-page.adults") }}</span>
      </div>
      <div class="p-2" v-if="Number(room.child)">
        <p class="m-0"><img src="assets/img/icon_plus.png" alt="icon_plus" /></p>
      </div>
      <div class="p-2" :style="lang === 'he'? 'direction:rtl;':'direction:ltr;'">
        <span v-if="room.child && Number(room.child) === 1">{{ room.child }} {{ $t("product-page.child") }}</span>
        <span v-if="room.child && Number(room.child) > 1">{{ room.child }} {{ $t("product-page.children") }}</span>
      </div>
      <div class="p-2" v-if="Number(room.infant)">
        <p class="m-0"><img src="assets/img/icon_plus.png" alt="icon_plus" /></p>
      </div>
      <div class="p-2" :style="lang === 'he'? 'direction:rtl;':'direction:ltr;'">
        <p v-if="room.infant && Number(room.infant) === 1">{{ room.infant }} {{ $t("product-page.infant") }}</p>
        <p v-if="room.infant && Number(room.infant) > 1">{{ room.infant }} {{ $t("product-page.infants") }}</p>
      </div>
      <div class="p-2">
        <p class="m-0"><img src="assets/img/Equal.png" alt="Equal" /></p>
      </div>
      <div class="p-2">
        <p class="m-0"> ₪{{ room.price }}</p>
        <p><s v-if="!isOdysseySite">₪{{ (room.oldPrice === room.price) ? (room.oldPrice * 1.1).toFixed(0):room.oldPrice }}</s></p>
      </div>
    </div>

    <button class="js-del-row" v-if="index > 0" @click="remove">
      <i class="far fa-window-close"></i>
    </button>
  </div>
</template>
<script>
import TemplatePriceTwo from './TemplatePriceTwo';

export default {
  name: 'PriceTwoTheme0',
  mixins: [TemplatePriceTwo],
};
</script>
<style scoped>
s {
  opacity: 0.5;
}
</style>
