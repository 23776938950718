import { mapGetters } from 'vuex';

export default {
  props: {
    index: {
      type: Number,
      default: 0,
    },
    room: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
    }),
    roomClassName() {
      const { room } = this;
      return room.roomClassName;
    },
  },
  methods: {
    remove() {
      this.$emit('closed', this.index);
    },
  },
};
